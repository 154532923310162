<template>
	<div class="container">
		<div class="page-sub-box ewm">
			<div class="pt-14">
				<div class="space-x-6 flex items-center">
					<div class="w-32">근무변경구분</div>
					<div>
						<DxSelectBox
							placeholder="근무변경"
							:styling-mode="config.stylingMode"
							v-model="formData.revTypeCd"
							:items="codes.revTypeCd"
							display-expr="value"
							value-expr="id"
						>
							<DxValidator>
								<DxRequiredRule message="근무변경은 필수입니다." />
							</DxValidator>
						</DxSelectBox>
					</div>
				</div>

				<div v-if="!formData.revTypeCd">
					<div class="space-y-4 py-8">
						<table class="table_form line-bin">
							<colgroup>
								<col style="width:130px;" />
								<col style="width:200px;" />
								<col style="width:130px;" />
								<col style="width:auto;" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="label5">소속</label>
									</th>
									<td colspan="3">{{ this.formData.deptNmPath }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">상담원</label>
									</th>
									<td colspan="3">{{ this.formData.agtNm }} [{{ this.formData.agtId }}]</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">신청일</label>
									</th>
									<td colspan="3">{{ this.formData.workDt }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">근무유형</label>
									</th>
									<td>{{ this.formData.workBasicNm }}</td>
									<th scope="row">
										<label for="label5">근무시간</label>
									</th>
									<td>{{ this.formData.workBasicStartTime }} - {{ this.formData.workBasicEndTime }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">시간외근무유형</label>
									</th>
									<td>{{ this.formData.workOverTimeNm }}</td>
									<th scope="row">
										<label for="label5">시간외근무시간</label>
									</th>
									<td>
										{{ this.formData.workOverTimeStartTime }} - {{ this.formData.workOverTimeEndTime }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div v-if="formData.revTypeCd == 'UPDATE_REQUEST'">
					<div class="space-y-4 py-8">
						<div class="sub_title_txt">
							<h2>신청자</h2>
						</div>
						<table class="table_form line-bin">
							<colgroup>
								<col style="width:130px;" />
								<col style="width:200px;" />
								<col style="width:130px;" />
								<col style="width:auto;" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="label5">소속</label>
									</th>
									<td colspan="3">{{ this.formData.deptNmPath }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">상담원</label>
									</th>
									<td colspan="3">{{ this.formData.agtNm }} [{{ this.formData.agtId }}]</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">신청일</label>
									</th>
									<td colspan="3">{{ this.formData.workDt }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">근무유형</label>
									</th>
									<td>{{ this.formData.workBasicNm }}</td>
									<th scope="row">
										<label for="label5">근무시간</label>
									</th>
									<td>{{ this.formData.workBasicStartTime }} - {{ this.formData.workBasicEndTime }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">시간외근무유형</label>
									</th>
									<td>{{ this.formData.workOverTimeNm }}</td>
									<th scope="row">
										<label for="label5">시간외근무시간</label>
									</th>
									<td>
										{{ this.formData.workOverTimeStartTime }} - {{ this.formData.workOverTimeEndTime }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="space-y-4 py-8">
						<div class="sub_title_txt">
							<h2>대직자</h2>
						</div>
						<table class="table_form line-bin">
							<colgroup>
								<col style="width:130px;" />
								<col style="width:200px;" />
								<col style="width:130px;" />
								<col style="width:auto;" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="label5">상담사 <span class="icon_require">필수항목</span></label>
									</th>
									<td colspan="3">
										<div class="flex gap-2">
											<DxTextBox
												:styling-mode="config.textStylingMode"
												:value="formData.replacementAgtNm"
												:width="120"
												:height="30"
												:read-only="true"
											>
												<DxValidator>
													<DxRequiredRule message="상담사는 필수입니다." />
												</DxValidator>
											</DxTextBox>
											<DxTextBox
												:styling-mode="formData.replacementAgtNm"
												:value="formData.replacementAgtid"
												:width="120"
												:height="30"
												:read-only="true"
											>
												<DxValidator>
													<DxRequiredRule message="상담사는 필수입니다." />
												</DxValidator>
											</DxTextBox>
											<DxButton icon="search" :width="40" :height="30" @click="onOpen" />
										</div>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">대직일</label>
									</th>
									<td colspan="3">{{ this.formData.replacementWorkDt }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">근무유형</label>
									</th>
									<td>
										{{ this.formData.replacementWorkBasicNm }}
									</td>
									<th scope="row">
										<label for="label5">근무시간</label>
									</th>
									<td>
										{{ this.formData.replacementWorkBasicStartTime }} - {{ this.formData.replacementWorkBasicEndTime }}
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">시간외근무유형</label>
									</th>
									<td>
										{{ this.formData.replacementWorkOverTimeNm }}
									</td>
									<th scope="row">
										<label for="label5">시간외근무시간</label>
									</th>
									<td>
										{{ this.formData.replacementWorkOverTimeStartTime }} - {{ this.formData.replacementWorkOverTimeEndTime }}
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">신청사유</label>
									</th>
									<td colspan="3">
										<DxTextArea
											:styling-mode="config.stylingMode"
											class="mr-4"
											:show-clear-button="true"
											width="300"
											:height="50"
											v-model="formData.approvalRequestReason"
										>
										</DxTextArea>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">첨부파일</label>
									</th>
									<td colspan="3">
										<DxTextBox
											:styling-mode="config.stylingMode"
											v-model="formData.originalFileName"
											:show-clear-button="true"
											id="dropzone-external"
											width="300"
											height="30"
										/>
										<DxFileUploader
											id="file-uploader"
											upload-mode="useButtons"
											dialog-trigger="#dropzone-external"
											:visible="false"
											@value-changed="uploadFile"
										/>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">결재정보</label>
									</th>
									<td colspan="3">
										<span>
											<div class="space-y-4">
												<div class="space-x-6 flex items-center">
													<span class="w-10">구분</span>
													<span class="w-40 flex justify-center">결재자명</span>
													<span class="w-20 flex justify-center">승인여부</span>
												</div>
												<div class="space-x-6 flex items-center">
													<span class="w-10">1차</span>
													<span class="w-40">
														<DxSelectBox
															placeholder="1차 결재자"
															:data-source="approval.firstAgt"
															display-expr="agtNm"
															value-expr="agtId"
															v-model="formData.primaryApproverId"
															:styling-mode="config.stylingMode"
														>
															<DxValidator>	
																<DxRequiredRule message="결재자는 필수입니다." />
															</DxValidator>
														</DxSelectBox>
													</span>
													<span class="w-20 flex justify-center">
														{{ formData.primaryApprovalStatusNm }}
													</span>
												</div>
												<div class="space-x-6 flex items-center">
													<span class="w-10">2차</span>
													<span class="w-40">
														<DxSelectBox
															placeholder="2차 결재자"
															:data-source="approval.secondAgt"
															display-expr="agtNm"
															value-expr="agtId"
															v-model="formData.secondaryApproverId"
															:styling-mode="config.stylingMode"
														>
														</DxSelectBox>
													</span>
													<span class="w-20 flex justify-center">
														{{ formData.secondaryApprovalStatusNm }}
													</span>
												</div>
												<div class="space-x-6 flex items-center">
													<span class="w-10">3차</span>
													<span class="w-40">
														<DxSelectBox
															placeholder="3차 결재자"
															:data-source="approval.thirdtAgt"
															display-expr="agtNm"
															value-expr="agtId"
															v-model="formData.tertiaryApproverId"
															:styling-mode="config.stylingMode"
														>
														</DxSelectBox>
													</span>
													<span class="w-20 flex justify-center">
														{{ formData.tertiaryApprovalStatusNm }}
													</span>
												</div>
											</div>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div v-else-if="formData.revTypeCd == 'DELETE_REQUEST'">
					<div class="space-y-4 py-8">
						<div class="sub_title_txt">
							<h2>신청자</h2>
						</div>
						<table class="table_form line-bin">
							<colgroup>
								<col style="width:130px;" />
								<col style="width:200px;" />
								<col style="width:130px;" />
								<col style="width:auto;" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="label5">소속</label>
									</th>
									<td colspan="3">{{ this.formData.deptNmPath }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">상담원</label>
									</th>
									<td colspan="3">{{ this.formData.agtNm }} [{{ this.formData.agtId }}]</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">신청일</label>
									</th>
									<td colspan="3">{{ this.formData.workDt }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">근무유형</label>
									</th>
									<td>{{ this.formData.workBasicNm }}</td>
									<th scope="row">
										<label for="label5">근무시간</label>
									</th>
									<td>{{ this.formData.workBasicStartTime }} - {{ this.formData.workBasicEndTime }}</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">시간외근무유형</label>
									</th>
									<td>{{ this.formData.workOverTimeNm }}</td>
									<th scope="row">
										<label for="label5">시간외근무시간</label>
									</th>
									<td>
										{{ this.formData.workOverTimeStartTime }} - {{ this.formData.workOverTimeEndTime }}
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">신청사유</label>
									</th>
									<td colspan="3">
										<DxTextArea
											:styling-mode="config.stylingMode"
											class="mr-4"
											:show-clear-button="true"
											width="300"
											:height="50"
											v-model="formData.approvalRequestReason"
										>
										</DxTextArea>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">첨부파일</label>
									</th>
									<td colspan="3">
										<DxTextBox
											:styling-mode="config.stylingMode"
											v-model="formData.originalFileName"
											:show-clear-button="true"
											id="dropzone-external"
											width="300"
											height="30"
										/>
										<DxFileUploader
											id="file-uploader"
											upload-mode="useButtons"
											dialog-trigger="#dropzone-external"
											:visible="false"
											@value-changed="uploadFile"
										/>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="label5">결재정보</label>
									</th>
									<td colspan="3">
										<div class="space-y-4">
											<div class="space-x-6 flex items-center">
												<span class="w-10">구분</span>
												<span class="w-40 flex justify-center">결재자명</span>
												<span class="w-20 flex justify-center">승인여부</span>
											</div>
											<div class="space-x-6 flex items-center">
												<span class="w-10">1차</span>
												<span class="w-40">
													<DxSelectBox
														placeholder="1차 결재자"
														:data-source="approval.firstAgt"
														display-expr="agtNm"
														value-expr="agtId"
														v-model="formData.primaryApproverId"
														:styling-mode="config.stylingMode"
													>
														<DxValidator>		
															<DxRequiredRule message="결재자는 필수입니다." />
														</DxValidator>
													</DxSelectBox>
												</span>
												<span class="w-20 flex justify-center">
													{{ formData.primaryApprovalStatusNm }}
												</span>
											</div>
											<div class="space-x-6 flex items-center">
												<span class="w-10">2차</span>
												<span class="w-40">
													<DxSelectBox
														placeholder="2차 결재자"
														:data-source="approval.secondAgt"
														display-expr="agtNm"
														value-expr="agtId"
														v-model="formData.secondaryApproverId"
														:styling-mode="config.stylingMode"
													>
													</DxSelectBox>
												</span>
												<span class="w-20 flex justify-center">
													{{ formData.secondaryApprovalStatusNm }}
												</span>
											</div>
											<div class="space-x-6 flex items-center">
												<span class="w-10">3차</span>
												<span class="w-40">
													<DxSelectBox
														placeholder="3차 결재자"
														:data-source="approval.thirdtAgt"
														display-expr="agtNm"
														value-expr="agtId"
														v-model="formData.tertiaryApproverId"
														:styling-mode="config.stylingMode"
													>
													</DxSelectBox>
												</span>
												<span class="w-20 flex justify-center">
													{{ formData.tertiaryApprovalStatusNm }}
												</span>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<section class="terms bottom-btn-box">
			<div class="bottom-btn-wrap">
				<DxButton
					text="결재요청"
					class="default filled txt_S medium"
					:width="90"
					:height="40"
					@click="onSaveFormData"
					:visible="!config.updateYn"
				/>
				<DxButton
					text="재기안"
					class="default filled txt_S medium"
					:width="90"
					:height="40"
					@click="onReSubmissionFormData"
					:visible="!config.reSubmission"
				/>
				<DxButton text="취	소" class="btn_XS white filled txt_S medium" :width="90" :height="40" @click="onCancelFormData" />
			</div>
		</section>

		<DxPopup
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:min-width="modal.initData ? modal.initData.width : null"
			:width="modal.initData ? modal.initData.width : null"
			:min-height="modal.initData ? modal.initData.height : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			v-model="modal.isOpened"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
				</div>
			</template>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="modal.saveButtonVisible"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						onConfirmModal();
					},
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
			/>
		</DxPopup>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxDataGrid, DxColumn, DxLoadPanel, DxLookup, DxSelection } from 'devextreme-vue/data-grid';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import ModalScheduleDetail from '@/pages/ewm/personal/work-schedule/modal-schedule-detail';

let vm = this;

export default {
	components: {
		DxButton,
		DxDateBox,
		DxTextBox,
		DxTextArea,
		DxFileUploader,
		DxSelectBox,
		DxDropDownBox,

		DxDataGrid,
		DxColumn,
		DxLoadPanel,
		DxLookup,
		DxSelection,

		DxRequiredRule,
		DxValidator,

		DxPopup,
		DxToolbarItem,
		ModalScheduleDetail,
	},
	data() {
		return {
			config: {
				stylingMode: 'outlined', //outlined, underlined, filled
				textStylingMode: 'filled',
				updateYn: false,
				reSubmission: false,
			},
			codes: {
				revTypeCd: [
					{ id: 'UPDATE_REQUEST', value: '근무대직' },
					{ id: 'DELETE_REQUEST', value: '근무삭제' },
				],
			},
			modal: {
				isOpened: false,
				initData: {},
				contentData: [],
				componentName: '',
			},
			formData: {},
			approval: {
				firstAgt: [],
				secondAgt: [],
				thirdtAgt: [],
			},
		};
	},
	computed: {},
	methods: {
		async workLineList() {
			const payload = {
				actionname: 'EWM_PERSONAL_VACATION_LINE',
				data: {
					approvalType: this.$_enums.ewm.approvalType.WORK.value,
				},
				loading: false,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.approval.firstAgt = res.data.data[0].approvalLine1;
				this.approval.secondAgt = res.data.data[0].approvalLine2;
				this.approval.thirdtAgt = res.data.data[0].approvalLine3;
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		async uploadFile(e) {
			const file = e.value[0];
			let formData = new FormData();
			formData.append('file', file);

			let payload = {
				actionname: 'COMMON_ATTACHED_FILE_UPLOAD',
				data: formData,
				loading: false,
			};

			try {
				let res = await this.CALL_API(payload);
				if (isSuccess(res)) {
					this.formData.attachFileGroupId = res.data.data[0].fileGroupId;
					this.formData.originalFileName = res.data.data[0].originalFileName;
				} else {
					this.$_Msg('이미지 업로드 실패 :(');
				}
			} catch (error) {
				this.$log.debug(error);
			}
		},
		onOpenModal(componentNm, componentInitData, data) {
			this.modal.currentComponent = componentNm;
			this.modal.initData = componentInitData;
			this.modal.contentData = data;
			this.isOpenModal(true);
		},
		/** @description: 팝업 오픈 체크 메서드 */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
		onOpen() {
			this.onOpenModal(
				'ModalScheduleDetail',
				{
					title: '대직자 리스트',
					buttons: {
						save: { text: '선택' },
						cancel: { text: '닫기' },
					},
					width: '60%',
					height: '50%',
				},
			);
		},
		/** @description: row 추가 */
		onConfirmModal() {
			if (!this.modal.contentData.val) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			this.formData.replacementScheduleId = this.modal.contentData.val[0].id;
			this.formData.replacementWorkDt = this.modal.contentData.val[0].workDt;
			this.formData.replacementAgtid = this.modal.contentData.val[0].agtId;
			this.formData.replacementAgtNm = this.modal.contentData.val[0].agtNm;
			this.formData.replacementDeptNmPath = this.modal.contentData.val[0].deptNmPath;
			this.formData.replacementWorkTypeId = this.modal.contentData.val[0].workBasicId;
			this.formData.replacementWorkBasicNm = this.modal.contentData.val[0].workBasicNm;
			this.formData.replacementWorkBasicStartTime = this.modal.contentData.val[0].workBasicStartTime;
			this.formData.replacementWorkTime = this.modal.contentData.val[0].workTimeMinutes;
			this.formData.replacementWorkBasicEndTime = this.modal.contentData.val[0].workBasicEndTime;
			this.formData.replacementWorkOverTimeNm = this.modal.contentData.val[0].workOverTimeNm;
			this.formData.replacementWorkOverTimeStartTime = this.modal.contentData.val[0].workOverTimeStartTime;
			this.formData.replacementWorkOverTimeEndTime = this.modal.contentData.val[0].workOverTimeEndTime;

			this.isOpenModal(false);
		},
		/** @description : 저장 메서드 */
		async onSaveFormData(e) {
			if (!e.validationGroup.validate().isValid) {
				return false;
			}

			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>근무변경신청</h2>` }))) {
				return;
			}

			let approvalData = {
				approvalType: this.$_enums.ewm.approvalType.WORK.value,
				requestId: this.formData.agtId,
				requestInfo: '대직자근무일: ' + this.formData.replacementWorkDt + '|신청자근무일: ' + this.formData.workDt,
				requestReason: this.formData.approvalRequestReason,
				fileGroupId: this.formData.attachFileGroupId,
				primaryApproverId: this.formData.primaryApproverId,
				secondaryApproverId: this.formData.secondaryApproverId,
				tertiaryApproverId: this.formData.tertiaryApproverId,
			};

			const payload1 = {
				actionname: 'EWM_APPROVAL_REQUEST_INSERT',
				data: approvalData,
			};

			const res1 = await this.CALL_EWM_API(payload1);

			if (isSuccess(res1)) {
				let payload2 = {};

				if(this.formData.revTypeCd == 'UPDATE_REQUEST'){
					payload2 = {
						actionname: 'EWM_PERSONAL_WORK_SCHEDULE_SAVE',
						data: {
							approvalId: res1.data.data[0],
							scheduleId: this.formData.id,
							replacementScheduleId: this.formData.replacementScheduleId,
						},
					};
				}else if(this.formData.revTypeCd == 'DELETE_REQUEST'){
					payload2 = {
						actionname: 'EWM_PERSONAL_WORK_SCHEDULE_DELETE',
						data: {
							approvalId: res1.data.data[0],
							scheduleId: this.formData.id,
						},
					};
				}

				const res2 = await this.CALL_EWM_API(payload2);

				if (isSuccess(res2)) {
					this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
					this.onCancelFormData();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		async onReSubmissionFormData(e) {
			if (!e.validationGroup.validate().isValid) {
				return false;
			}
			
			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>근무변경신청</h2>` }))) {
				return;
			}
			const payload1 = {
				actionname: 'EWM_APPROVAL_PROCESSING_UPDATE',
				path: '/' + this.formData.approvalId,
				data: {
					approvalStatus: 'RE_SUBMISSION',
					approvalReason: this.formData.approvalRequestReason,
					requestID: this.formData.agtId,
					requestReason: this.formData.approvalRequestReason,
					fileGroupId: this.formData.attachFileGroupId,
					primaryApproverId: this.formData.primaryApproverId,
					secondaryApproverId: this.formData.secondaryApproverId,
					tertiaryApproverId: this.formData.tertiaryApproverId,
				},
			};

			const res1 = await this.CALL_EWM_API(payload1);

			if (isSuccess(res1)) {
				let payload2 = {};

				if(this.formData.revTypeCd === 'UPDATE_REQUEST'){
					payload2 = {
						actionname: 'EWM_PERSONAL_WORK_SCHEDULE_SAVE',
						data: {
							approvalId: this.formData.approvalId,
							scheduleId: this.formData.id,
							replacementScheduleId: this.formData.replacementScheduleId,
						},
					};
				}else if(this.formData.revTypeCd == 'DELETE_REQUEST'){
					payload2 = {
						actionname: 'EWM_PERSONAL_WORK_SCHEDULE_DELETE',
						data: {
							approvalId: this.formData.approvalId,
							scheduleId: this.formData.id,
						},
					};
				}

				const res2 = await this.CALL_EWM_API(payload2);

				if (isSuccess(res2)) {
					this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
					this.onCancelFormData();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		onCancelFormData() {
			this.$router.push({ path: '/ewm/personal/work-schedule' });
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
      this.reqParams = this.$store.getters.getDetailParams;
      this.config.updateYn = this.reqParams ? this.reqParams.updateYn : false;
      this.config.reSubmission = this.reqParams ? this.reqParams.reSubmission : false;
      this.formData = this.reqParams.formData;
      this.formData.primaryApprovalStatusNm = this.$_enums.ewm.approvalStatus.values.find(e => this.formData.primaryApprovalStatus === e.value)?.label;
      this.formData.secondaryApprovalStatusNm = this.$_enums.ewm.approvalStatus.values.find(e => this.formData.secondaryApprovalStatus === e.value)?.label;
      this.formData.tertiaryApprovalStatusNm = this.$_enums.ewm.approvalStatus.values.find(e => this.formData.tertiaryApprovalStatus === e.value)?.label;
      this.workLineList(); //결재라인 조회
    },
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>
